<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <!-- <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      /> -->
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLocale(localeObj.locale)"
    >
      <!-- <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      /> -->
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data(){
    return {
      userData: this.$store.getters['user/getUserData'],
      locales: this.$store.getters['general/getLocales']
    }
  },
  methods:{
    changeLocale(localeObj_locale){
      this.$i18n.locale = localeObj_locale
      this.$store.commit('user/changeLang', localeObj_locale)
      this.save(localeObj_locale)
    },
    async save(localeObj_locale){
      let data = {
        context: 'lang',
        lang: localeObj_locale,
      }
      await this.$http.post('editUser', data).then( response => {
          console.log(response.data)
      })       
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      if(this.userData.lang) {
        return this.locales.find(l => l.locale === this.userData.lang)
      }

      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
 
}
</script>

<style>

</style>
